import order_api_spec from './orderapi.yaml';
import order_api_spec_cn from './orderapi-cn.yaml';
import order_api_spec_id from './orderapi-id.yaml';
import order_api_spec_th from './orderapi-th.yaml';
import order_api_spec_vn from './orderapi-vn.yaml';

export class Language {
    static en = new Language("en")
    static cn = new Language("cn")
    static id = new Language("id")
    static th = new Language("th")
    static vn = new Language("vn")

    constructor(lg) {
        this.lg = lg
    }
}

export function isValidLanguage(lang) {
    return Object.keys(Language).includes(lang)
}

export function getApiSpec(lg) {
    switch (lg) {
        case Language.cn.lg:
            return order_api_spec_cn;
        case Language.id.lg:
            return order_api_spec_id;
        case Language.th.lg:
            return order_api_spec_th;
        case Language.vn.lg:
            return order_api_spec_vn;
        case Language.en.lg:
        default:
            return order_api_spec;
    }
}
