import Select from "react-select";
import './language-button.css';
import {Language} from "../specs/enums";
import {useNavigate, useParams} from "react-router-dom";

export function LanguageButton() {
    let {language} = useParams();
    let navigate = useNavigate();

    const languageOptions = [
        {value: Language.en, label: 'English'},
        {value: Language.cn, label: 'Chinese (中文)'},
        {value: Language.id, label: 'Bahasa Indonesia'},
        {value: Language.th, label: 'Thai (ภาษาไทย)'},
        {value: Language.vn, label: 'Vietnamese (Tiếng Việt)'},
    ]

    return (
        <div className="floating-language-button">
            <Select options={languageOptions}
                    value={languageOptions.filter(({value}) => value.lg === language)}
                    onChange={(lang) => navigate(`/${lang.value.lg}`)}
                    styles={{
                        control: base => ({
                            ...base,
                            fontSize: "0.8em",
                            fontFamily: "Montserrat",
                            borderRadius: "0"
                        }),
                        menu: base => ({
                            ...base,
                            fontSize: "0.8em",
                            fontFamily: "Montserrat",
                            borderRadius: "0"
                        }),
                    }}/>
        </div>
    )
}
