import './App.css';
import TagManager from 'react-gtm-module'
import {RedocStandalone} from 'redoc';
import {Helmet} from 'react-helmet'
import {useEffect} from "react";
import {getApiSpec, isValidLanguage} from "./specs/enums";
import {LanguageButton} from "./components/language-button";
import {useNavigate, useParams} from "react-router-dom";
// eslint-disable-next-line
import id_zone_code_mapping from './resources/ID_zone_code_mapping.csv';

const TITLE = 'Ninja Van API'

const TAG_MANAGER_ARGS = {
    gtmId: 'GTM-PC9LTW2'
}

TagManager.initialize(TAG_MANAGER_ARGS)

function App() {
    let {language} = useParams();
    let navigate = useNavigate();

    useEffect(() => {
        if (!isValidLanguage(language)) {
            navigate('/en')
        }
    })
    return (
        <div className="App">
            <LanguageButton/>
            <Helmet>
                <title>{TITLE}</title>
                <link rel="icon" href="https://www.ninjavan.co/assets/favicons/ninja-favicon.ico"
                      type="image/x-icon"/>
                <link href="https://fonts.googleapis.com/css?family=Montserrat:300,400,700|Roboto:300,400,700"
                      rel="stylesheet"/>
            </Helmet>
            <RedocStandalone specUrl={getApiSpec(language)}/>
        </div>
    );
}

export default App;
